// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-edits-js": () => import("./../../../src/pages/edits.js" /* webpackChunkName: "component---src-pages-edits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-faves-js": () => import("./../../../src/pages/latest-faves.js" /* webpackChunkName: "component---src-pages-latest-faves-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-shop-my-style-js": () => import("./../../../src/pages/shop-my-style.js" /* webpackChunkName: "component---src-pages-shop-my-style-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-edit-js": () => import("./../../../src/templates/edit.js" /* webpackChunkName: "component---src-templates-edit-js" */),
  "component---src-templates-like-to-know-item-js": () => import("./../../../src/templates/likeToKnowItem.js" /* webpackChunkName: "component---src-templates-like-to-know-item-js" */),
  "component---src-templates-standard-content-page-js": () => import("./../../../src/templates/standardContentPage.js" /* webpackChunkName: "component---src-templates-standard-content-page-js" */)
}

